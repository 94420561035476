<script lang="ts">
	export let label: string;
</script>

<style>
  .container {
    padding: 8px;
    cursor: pointer;
    transform: rotate(0.03deg);
  }
</style>

<div class="container">
  {label}
</div>
