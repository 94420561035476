export const BASE_CANVAS_WIDTH = 1600;
export const BASE_CANVAS_HEIGHT = 1250;
export const BASE_LOGO_Y = 1000;
export const images = [
    {
        position: {
            x: BASE_CANVAS_WIDTH - 988,
            y: 0,
            flipColor: [0.74, 0.08, 0.16, 1.0],
        },
        image: {
            src: "/assets/shinku_gold.webp",
            width: 988,
            height: 988,
        },
    },
    {
        position: {
            x: 0,
            y: 0,
            flipColor: [0.27, 0.84, 0.91, 1.0],
        },
        image: {
            src: "/assets/romi_scarlet.webp",
            width: 1130,
            height: 1130,
        },
    },
    {
        position: {
            x: BASE_CANVAS_WIDTH - (BASE_CANVAS_WIDTH / 2 - 22) - 553 - 24,
            y: BASE_CANVAS_HEIGHT - 840 - 24,
            flipColor: [0.88, 0.88, 0.88, 1.0],
        },
        image: {
            src: "/assets/sui_gray.webp",
            width: 840,
            height: 840,
        },
    },
    {
        position: {
            x: BASE_CANVAS_WIDTH - BASE_CANVAS_WIDTH / 2 - 22,
            y: BASE_CANVAS_HEIGHT - 840 - 24,
            flipColor: [0.33, 0.33, 0.33, 1.0],
        },
        image: {
            src: "/assets/sui_silver.webp",
            width: 840,
            height: 840,
        },
    },
];
export const logoDatas = new Map([
    [
        "ryoha",
        {
            src: "/assets/logo_ryoha.webp",
            destinationX: 50,
        },
    ],
    [
        "infinity",
        {
            src: "/assets/logo_infinity.webp",
            destinationX: 500,
        },
    ],
]);
