import { mdiChevronLeft, mdiChevronRight, mdiOpenInNew, mdiGithub, mdiTwitter, mdiGmail } from '@mdi/js';
const mdi = new Map([
    ['chevron-left', mdiChevronLeft],
    ['chevron-right', mdiChevronRight],
    ['open-in-new', mdiOpenInNew],
    ['github', mdiGithub],
    ['twitter', mdiTwitter],
    ['gmail', mdiGmail],
]);
export default mdi;
